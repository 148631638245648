import BannerImage from "./BannerImage"

class DynamicBanner {
  constructor() {
    this.img =
      "https://cdn.vpapps.cloud/bJZEnw5N5BA/lWjM5lgNAO7/d1f0d64f-9f66-4595-84c2-5bcdc865c9f2.png"
    this.alt = null
    this.imgTo = null
    this.textTo = null
    this.title = null
    this.description = null
    this.userEngagements = {}
    this.linkType = 1
    this.socialMedia = { items: [] }
    this.wrapperName = defineNuxtLink({})
  }

  fromData(data) {
    if (data) {
      this.alt = data.alt

      if (data.bannerImages && data.bannerImages.desktop) {
        this.img = `${data.bannerImages.desktop}?q=80&format=webp`
      }

      this.title = data.title
      this.imgTo = data.url
      this.description = data.description
    }
  }

  fromQuery(data, { properties, socialMedia }, deviceType) {
    if (data) {
      let showTitle = true
      let showDescription = true
      let showSocialMedia = true
      let showBackgroundImage = true
      if (properties && properties.length > 0) {
        const indexFoundTitle = properties.findIndex((v) => v === "title")

        if (indexFoundTitle > -1) {
          showTitle = true
        } else {
          showTitle = false
        }

        const indexFoundDescription = properties.findIndex(
          (v) => v === "description"
        )

        if (indexFoundDescription > -1) {
          showDescription = true
        } else {
          showDescription = false
        }

        const indexFoundSocialMedia = properties.findIndex(
          (v) => v === "socialMedia"
        )

        if (indexFoundSocialMedia > -1) {
          showSocialMedia = true
        } else {
          showSocialMedia = false
        }

        const indexFoundBackgroundImage = properties.findIndex(
          (v) => v === "bannerImages"
        )

        if (indexFoundBackgroundImage > -1) {
          showBackgroundImage = true
        } else {
          showBackgroundImage = false
        }
      }

      if (data?.socialMedias && showSocialMedia) {
        this.socialMedia = {
          items: socialMedia?.items.map((social, i) => {
            social.url = data.socialMedias[social.id]
            return social
          }),
        }
      }

      if (
        this.socialMedia &&
        this.socialMedia.items &&
        this.socialMedia.items.length > 0
      )
        this.socialMedia = {
          items: this.socialMedia.items.filter((item) => item.url),
        }

      this.socialMedia.query = data.socialMedias
      this.socialMedia.data = socialMedia

      this.alt = data.alt

      if (data.bannerImages && showBackgroundImage) {
        const bannerImage = new BannerImage(data.bannerImages)
        const image = bannerImage.getImage(deviceType)

        this.img = image
      } else {
        this.wrapperName = "div"
      }

      if (showTitle) {
        this.title = data.title
      }

      if (showDescription) {
        this.description = data.description
      }

      this.imgTo = data.url
    }
  }
}

export default DynamicBanner
