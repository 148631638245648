import { ALIGN_ITEMS_OPTIONS, JUSTIFY_CONTENT_OPTIONS, TEXT_ALIGNMENT } from '~~/constants/style'
import { formatHeight, formatMargin, formatPadding, formatWidth } from '../../style'

import { DimensionValues } from '~/models/general/DimensionValues'
import { formatFontStyle } from '../../style/font'
import { getBorder } from '~/models/general/BorderModel'

class BannerStyle {
  constructor() {

    this.wrapperStyle = new BannerWrapperModel()
    this.titleStyle = {}
    this.descriptionStyle = {}
    this.contentStyle = {}
    this.contentClasses = ''
    this.imageStyle = null

  }

  fromQuery(data) {
    this.titleStyle = {
      ...formatFontStyle(data.titleStyle?.fontStyle),
      ...formatMargin(data.titleStyle.margin),
    }
    this.descriptionStyle =
    {
      ...formatFontStyle(data.descriptionStyle.fontStyle),
      ...formatMargin(data.descriptionStyle.margin),
    }

    this.contentClasses = `${ALIGN_ITEMS_OPTIONS[data.horizontalPosition] || 'items-start'} ${JUSTIFY_CONTENT_OPTIONS[data.verticalPosition] || 'justify-start'} ${TEXT_ALIGNMENT[data.contentAlignment] || 'text-left'}`
    const { width, height, alignment, padding, aspectRatio, imageStyle } = data
    const style = new BannerWrapperModel()
    style.fromQuery({ width, height, alignment, aspectRatio, imageStyle })


    if (data.borderProperties?.show === true) {
      this.borderStyle = getBorder(data?.borderProperties);
    } else {
      this.borderStyle = {};
    }

    this.wrapperStyle = {
      ...style,
      ...this.borderStyle
    };

    this.contentStyle = formatPadding(padding)

    this.imageStyle = data?.imageStyle
  }
}

export class BannerWrapperModel {
  constructor() {
    this.width = null
    this.height = null
    this.margin = null
    this.aspectRatio = null
    this.backgroundSize = 'cover'
  }

  fromQuery({ width = 100, height = 'auto', alignment = null, aspectRatio = null, imageStyle }) {
    if (width) this.width = formatWidth(width)

    switch (alignment) {
      case 'left':
        this.margin = '0 auto 0 0'
        break
      case 'center':
        this.margin = '0 auto'
        break
      case 'right':
        this.margin = '0 0 0 auto'
        break
      default:
        this.margin = '0 0'
        break
    }

    this.backgroundSize = imageStyle?.backgroundSize


    if (aspectRatio && aspectRatio !== '/') {
      this.aspectRatio = aspectRatio
    } else {
      this.height = formatHeight(height)
      this.minHeight = this.height
    }
  }
}

export default BannerStyle
