import { formatButtonStyle } from '~/helpers/button/button'
import { formatIconStyle } from "~/helpers/icon/icon"

class StaticBanner {
    constructor() {

        this.img = 'https://cdn.vpapps.cloud/bJZEnw5N5BA/lWjM5lgNAO7/d1f0d64f-9f66-4595-84c2-5bcdc865c9f2.png'
        this.alt = null
        this.imgTo = null
        this.textTo = null
        this.title = null
        this.description = null
        this.userEngagements = {}
        this.linkType = 1
        this.wrapperName = defineNuxtLink({})
        this.socialMedia = { items: [] }
        this.isRichText = false
        this.videoSrc = null
        this.autostart = false
        this.canControlAudio = false
        this.clickable = true

        this.buttons = []
    }

    fromQuery({ props, children = null }) {
        const { data, socialMedia, properties } = props

        if (!data) { throw ('Static Banner no data error') }

        if (data.video && data.video.isActive) {
            this.videoSrc = data.video.source
            this.autostart = data.video.isAutoStart
            this.canControlAudio = data.video.canControlAudio
        }

        this.alt = data.alt

        if (data.img && data.img.includes('.gif')) {
            this.img = data.img
        } else {
            this.img = data.img ? data.img + '?q=80&format=webp' : null
        }
        this.title = data.title
        this.imgTo = data.imgTo
        this.description = data.description
        this.isRichText = data.isRichText
        this.clickable = data.clickable

        this.linkType = data.linkType

        if (!data.imgTo) {
            this.wrapperName = 'div'

        } else {
            this.wrapperName = data.linkType === 1 ? 'a' : defineNuxtLink({})
        }

        const socialMediaIndexFound = properties?.findIndex(v => v === 'social-media')

        if (socialMediaIndexFound > -1)
            this.socialMedia = socialMedia

        if (this.socialMedia && this.socialMedia.items.length > 0)
            this.socialMedia = { items: this.socialMedia.items.filter(item => item.url !== '') }

        if (children?.buttons) {
            this.buttons = children.buttons.map(e => {
                return {
                    ...e,
                    icon: formatIconStyle(e.icon),
                    style: formatButtonStyle(e.style),
                }
            })
        }

        if (props.buttons?.length) {
            this.buttons = props.buttons.map(e => {
                return {
                    ...e,
                    icon: formatIconStyle(e.icon),
                    style: formatButtonStyle(e.style),
                }
            })
        }

    }
}

export default StaticBanner