class BannerImage {
    constructor(data) {
        this.bannerImages = data
    }

    getImage(deviceType) {
        // console.error('device type', deviceType)
        if (!this.bannerImages[deviceType]) return ''

        return `${this.bannerImages[deviceType]}?q=100`
    }

}

export default BannerImage